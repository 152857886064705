import { ICellRendererComp, ICellRendererParams } from 'ag-grid-enterprise'

export class AgGridComponenteDeImagem implements ICellRendererComp {
	eGui!: HTMLSpanElement;

	// Optional: Params for rendering. The same params that are passed to the cellRenderer function.
	init(params: ICellRendererParams) {
		const componenteDeImagem: HTMLImageElement = document.createElement('img')
		componenteDeImagem.src = params.value ? `${params.value}` : 'https://i.pinimg.com/originals/61/3c/ea/613ceaae3543acdf381af20da47612dc.png'
		componenteDeImagem.setAttribute('class', 'imagem');

		this.eGui = document.createElement('span');
		this.eGui.appendChild(componenteDeImagem);
	}

	// Required: Return the DOM element of the component, this is what the grid puts into the cell
	getGui() {
		return this.eGui;
	}

	// Required: Get the cell to refresh.
	refresh(params: ICellRendererParams): boolean {
		console.log('params2', params)
		return false;
	}
}