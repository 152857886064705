










































import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import Confirmacao from '../ui/Confirmacao.vue'
import type { Relatorio, ViewRelatorio } from '@/models/views/ViewRelatorio'
import { FindRelatorioUseCase } from '@/usecases'
import { downloadFilesFromS3 } from '@/shareds/s3/files'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DetalhesDeRelatorioDeGrid extends Vue {
	@Prop({ type: Object }) relatorioSelecionado!: ViewRelatorio
	@Prop({ type: Boolean, default: false }) header!: boolean

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	statusColor = statusColor

	findUseCase = new FindRelatorioUseCase()
	relatorios: Relatorio[] = []
	cancelando: string[] = []

	itensExemplo: any[] = []

	carregando = false
	baixando = false

	totalRegistros = -1

	get headerExemplo() {
		return this.relatorioSelecionado.colunas.map(coluna => {
			return {
				text: coluna.nomeColuna,
				value: coluna.colunaDaView,
				sorteable: false,
				descricao: coluna.descricao,
			}
		})
	}

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	dadosDeExemplo: any[] = []

	get listaDeRelatoriosFormatada() {
		return this.relatorios
	}

	@Watch('relatorioSelecionado', {immediate: true})
	async carregarDadosExemplo() {
		const colunasOrdenadas = this.relatorioSelecionado.colunas.sort(this.ordenar)

		const objeto = {}
		colunasOrdenadas.forEach(coluna => {
			objeto[coluna.colunaDaView] = this.converterTipoParaUsuario(coluna.tipoColuna)
		})

		this.dadosDeExemplo = [objeto]
	}

	ordenar(a, b){
		return a.sequencia - b.sequencia;
	}

	baixarRelatorio(relatorioSelecionado) {
		try {
			this.baixando = true
			const nomeDoArquivo = decodeURIComponent(relatorioSelecionado.urlArquivo.substring(relatorioSelecionado.urlArquivo.lastIndexOf('/') + 1))
			const caminhoDaPasta = `relatorios/${relatorioSelecionado.usuarioId}`
			downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
			
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.baixando = false
		}
	}

	converterTipoParaUsuario(tipo: string) {
		switch(tipo) {
			case 'number':
				return 'numérico'
			case 'date':
				return 'data/hora'
			case 'boolean':
				return 'verdadeiro/falso'
			default:
				return 'texto'

		}
	}
}
export function statusColor(status: string) {
	if(status === 'Concluido') return 'success--text font-weight-bold'
	if(status === 'Pendente') return 'warning--text font-weight-bold'
	if(status === 'Cancelado') return 'error--text font-weight-bold'
}
